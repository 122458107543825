export function useUtmCookies() {
  const oneWeekFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) /* 1 week */
  let domain = getCookieDomain()

  const ref = useCookie("finq_utm_ref", { expires: oneWeekFromNow, domain })
  const adId = useCookie("finq_utm_ad_id", { expires: oneWeekFromNow, domain })
  const lastClickRef = useCookie("finq_utm_last_click_ref", { expires: oneWeekFromNow, domain })
  const referrerUrl = useCookie(getFullCookieName("finq_referrer_url"), { expires: oneWeekFromNow, domain })
  const id = useCookie(getFullCookieName("finq_utm_id"), { expires: oneWeekFromNow, domain })
  const source = useCookie(getFullCookieName("finq_utm_source"), { expires: oneWeekFromNow, domain })
  const medium = useCookie(getFullCookieName("finq_utm_medium"), { expires: oneWeekFromNow, domain })
  const campaign = useCookie(getFullCookieName("finq_utm_campaign"), { expires: oneWeekFromNow, domain })
  const content = useCookie(getFullCookieName("finq_utm_content"), { expires: oneWeekFromNow, domain })
  const term = useCookie(getFullCookieName("finq_utm_term"), { expires: oneWeekFromNow, domain })

  return {
    ref,
    adId,
    lastClickRef,
    referrerUrl,
    id,
    source,
    medium,
    campaign,
    content,
    term,
  }
}

function getCookieDomain() {
  const parts = window?.location.host.split(".") || []
  const finqaiIndex = parts.indexOf("finqai")
  if (finqaiIndex > 0) {
    parts.shift()
  }

  return `.${parts.join(".")}`
}

function getFullCookieName(name: string) {
  const env = useRuntimeConfig().public.NUXT_PUBLIC_ENVIRONMENT

  return `${name}${env.includes("prod") ? "" : "_" + env}`
}

export function addUtmObject<T>(data: T): T & {
  utm: {
    ref: string | null | undefined
    adId: string | null | undefined
    lastClickRef: string | null | undefined
  }
} {
  const { ref, adId, lastClickRef } = useUtmCookies()

  return {
    ...data,
    utm: {
      ref: ref.value,
      adId: adId.value,
      lastClickRef: lastClickRef.value,
    },
  }
}
