export default defineNuxtPlugin({
  name: "html-format",
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive("html-format", {
      getSSRProps(binding) {
        return {
          innerHTML: transformTextMarks(binding.value),
        }
      },
      created(el, binding) {
        el.innerHTML = transformTextMarks(binding.value)
      },
      updated(el, binding) {
        if (binding.value === binding.oldValue) return
        el.innerHTML = transformTextMarks(binding.value)
      },
    })
  },
})
