import type { RouteLocationNormalized } from "vue-router"

import { stripRouteNameSuffix } from "@finq/app-base/composables/routes-utils"

import { BaseApiService, KyOptions } from "./api.service"

export class AnalyticsServiceRaw extends BaseApiService {
  public reportPageView = async (route: RouteLocationNormalized, config: KyOptions = {}) => {
    const pageViewEvent = this.generatePageViewEvent(route)
    return this.instance.post("/analytics/page-view", pageViewEvent, config)
  }

  private generatePageViewEvent = (route: RouteLocationNormalized): PageViewEvent => {
    return {
      page: {
        path: route.path,
        name: stripRouteNameSuffix(route.name),
        url: location?.href,
      },
      referrer: {
        url: document.referrer,
      },
    }
  }
}

export interface PageViewEvent {
  page: Page
  referrer: Page
}

interface Page {
  path?: string
  fullPath?: string
  url?: string
}

export const AnalyticsService = new AnalyticsServiceRaw()
