import * as Sentry from "@sentry/vue"
import { merge } from "lodash-es"

import type { PlanCode, PricingProductEnum } from "@finq/pricing/types"

import { UserSubscription } from "../lib/types/user"

const USER_LS_KEY = "user"

const userRef = useLocalStorage<User | null>("user", null, {
  serializer: { read: JSON.parse, write: JSON.stringify },
  listenToStorageChanges: false,
  shallow: true,
  deep: true,
})

const auth = ref({
  popupOpen: false,
  error: null,
})

export const useUser = () => {
  const user = toRef(userRef)

  const userExists = computed(() => {
    const hasUser = user.value !== null
    const mustHaveFields = ["id", "email"]

    const hasMustHaveFields = mustHaveFields.every(
      (field) => isKey(user.value!, field) && !!user.value![field]
    )

    return hasUser && hasMustHaveFields
  })

  const firstName = computed(() => {
    if (!user.value) return undefined

    return user?.value.firstName || displayName.value
  })

  const displayName = computed(() => {
    if (!user.value) return undefined

    const { lastName, firstName, email } = user.value

    if (lastName && firstName) return firstName + " " + lastName
    else if (firstName) return firstName
    else return email.split("@")[0]
  })

  const isAuthenticated = computed(() => {
    if (!userExists.value) return false

    return Boolean(user.value?.accessToken && user.value?.linkToAuth0Id)
  })

  const isMarketingUser = computed(() => {
    if (!userExists.value) return false

    return !!user.value && !isAuthenticated.value
  })

  const authExpirationDate = computed(() => {
    const loginTimestamp = user.value?.loginTimestamp || 0

    if (!user.value?.expiresIn) return new Date(loginTimestamp)

    const timeStamp = loginTimestamp + user.value?.expiresIn * 1000

    return new Date(timeStamp)
  })

  const isTokenExpired = computed(() => {
    if (!user.value) return false
    else if ((isMarketingUser.value || isAuthenticated.value) && !user.value.expiresIn) return false

    const today = Date.now()
    const diff = authExpirationDate.value.getTime() - today
    const minutes = diff / 60000

    return minutes < 1
  })

  function isUserExists() {
    try {
      const lsUser = localStorage.getItem(USER_LS_KEY)

      if (typeof lsUser !== "string") return false

      return !!lsUser && "id" in (JSON.parse(lsUser) as User)
    } catch {
      return false
    }
  }

  const getSubscriptions = (productId: PricingProductEnum): UserSubscription[] => {
    return user.value?.subscriptions?.filter((sub: UserSubscription) => sub.productId === productId) || []
  }

  /**
   * @param productIds - single or array of productIds
   * @param planCodes - array of planCodes (optional)
   * @returns
   */
  function canAccessProduct(
    productIds: PricingProductEnum | PricingProductEnum[],
    planCodes?: PlanCode[]
  ): boolean {
    const ids = toArray(productIds)
    const subscriptions = ids.flatMap(getSubscriptions)

    if (!ids.length || !userExists.value || !subscriptions.length) return false

    // If planCodes are not provided, we just check if the user has any subscription to the product
    if (planCodes?.length) {
      if (ids.length > 1) {
        console.error("[useUser]: Multiple productIds are not supported when planCodes are provided")
        return false
      }

      return subscriptions.some((sub) => ids.includes(sub.productId) && planCodes.includes(sub.planCode))
    }

    return subscriptions.some((sub) => ids.includes(sub.productId))
  }

  /** @param user - Saves user to localStorage */
  function saveUser(data: User | { error: any }) {
    // Prevent axios errors from going in to the user object
    if ("error" in data) return

    data.loginTimestamp = Date.now()
    data.accessToken = data.accessToken || user.value?.accessToken
    user.value = data

    if (Sentry.isInitialized()) {
      Sentry.setUser({
        email: user.value?.email,
        username: displayName.value,
        id: user.value?.id,
      })
    }

    return user.value
  }

  /** @param user - Smart merging new user data into Local Storage */
  function mergeUser(data: Partial<User> | { error: any }) {
    // Prevent axios errors from going in to the user object
    if ("error" in data) return

    const mergedData = toRef(merge(user.value, data))
    return saveUser(mergedData.value)
  }
  /** Removes from localStorages */
  function deleteUser() {
    user.value = null

    if (Sentry.isInitialized()) {
      Sentry.setUser(null)
    }
  }

  return {
    user,
    auth,
    userExists,
    firstName,
    displayName,
    isAuthenticated,
    isMarketingUser,
    authExpirationDate,
    isTokenExpired,
    isUserExists,
    canAccessProduct,
    saveUser,
    mergeUser,
    deleteUser,
  }
}
