import { useQueryClient } from "@tanstack/vue-query"
import { LocationQuery } from "vue-router"

export function useAppInit(): void {
  useMe() // fetch user data
  useInitAnalytics() // track page views
  useIdleTracking() // track idle time
  useInitUtmCookies() // track utm cookies
  useResetQueriesOnUserChange() // reset queries on user change
}

function useInitAnalytics() {
  const cfg = useAppConfig()
  const gtm = useGtm()

  tryOnMounted(() => {
    if (!cfg.analytics?.enabled || gtm.isFirstTime.value) return

    gtm.consentUpdate({ approved: gtm.cookiesAccepted.value })
  })
}

function useInitUtmCookies() {
  const { referrerUrl, id, source, medium, campaign, content, term } = useUtmCookies()
  const { query } = useRoute()

  watchEffect(() => {
    if (!isServer && document.referrer) {
      const hostname = new URL(document.referrer).hostname
      if (
        !hostname.endsWith("finqai.co.il") &&
        !hostname.endsWith("finqai.com") &&
        !hostname.endsWith("localhost")
      ) {
        referrerUrl.value = document.referrer
      }
    }
    if (needToUpdateUtm(query)) {
      id.value = String(query.utm_id)
      source.value = String(query.utm_source)
      medium.value = String(query.utm_medium)
      campaign.value = String(query.utm_campaign)
      content.value = String(query.utm_content)
      term.value = String(query.utm_term)
    }
  })
}

function useResetQueriesOnUserChange() {
  const { isAuthenticated } = useUser()
  const queryClient = useQueryClient()

  watch(isAuthenticated, () => {
    queryClient.resetQueries()
  })
}

function needToUpdateUtm(query: LocationQuery) {
  return (
    query.utm_id ||
    query.utm_source ||
    query.utm_medium ||
    query.utm_campaign ||
    query.utm_content ||
    query.utm_term
  )
}
