import dayjs from "dayjs"
import "dayjs/locale/he"
import advancedFormat from "dayjs/plugin/advancedFormat"
import duration from "dayjs/plugin/duration"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"

export const useDayjs = () => {
  const { locale } = useI18n()

  // Load dayjs plugins
  dayjs.extend(advancedFormat)
  dayjs.extend(localizedFormat)
  dayjs.extend(duration)
  dayjs.extend(relativeTime)

  // Listen to locale changes and update dayjs locale
  watchEffect(() => {
    dayjs.locale(locale.value)
  })

  return dayjs
}
