import revive_payload_client_7g1ccI40d4 from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gYVbXGTzxl from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vzvEDygWX8 from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_39dlftjCG6 from "/usr/src/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.22.4_vite@5.4.7_vue@3.5.8/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_spIVh6LxMz from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xvMracpVnI from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4mKMGWnOJp from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PitLkfdRlT from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QCWv1ySeMy from "/usr/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.22.4_typescript@5.6.2_vue@3.5.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/apps/finq-finance/.nuxt/components.plugin.mjs";
import prefetch_client_dSdml09SBo from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.8_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_2PjqlAbsdy from "/usr/src/node_modules/.pnpm/@vueuse+motion@2.2.5_vue@3.5.8/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_ziYcKwnJdq from "/usr/src/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_2ZsSIYmBZI from "/usr/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.22.4_vue@3.5.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_nbjn8VXmim from "/usr/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.22.4_vue@3.5.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import eventBus_client_6G4UJ8ZbRL from "/usr/src/apps/base/src/plugins/eventBus.client.ts";
import formatHtml_g8oRQLd2uu from "/usr/src/apps/base/src/plugins/formatHtml.ts";
import gtm_faEZkqnVWN from "/usr/src/apps/base/src/plugins/gtm.ts";
import intersect_ellX5rVgT3 from "/usr/src/apps/base/src/plugins/intersect.ts";
import localStorage_MHY01ePQwD from "/usr/src/apps/base/src/plugins/localStorage.ts";
import pageView_LHDLtaCFew from "/usr/src/apps/base/src/plugins/pageView.ts";
import recaptcha_wOTe5uVF3s from "/usr/src/apps/base/src/plugins/recaptcha.ts";
import vue_query_FsGdL7i3dD from "/usr/src/apps/base/src/plugins/vue-query.ts";
export default [
  revive_payload_client_7g1ccI40d4,
  unhead_gYVbXGTzxl,
  router_vzvEDygWX8,
  _0_siteConfig_39dlftjCG6,
  payload_client_spIVh6LxMz,
  navigation_repaint_client_xvMracpVnI,
  check_outdated_build_client_4mKMGWnOJp,
  chunk_reload_client_PitLkfdRlT,
  plugin_vue3_QCWv1ySeMy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dSdml09SBo,
  motion_2PjqlAbsdy,
  plugin_ziYcKwnJdq,
  switch_locale_path_ssr_2ZsSIYmBZI,
  i18n_nbjn8VXmim,
  eventBus_client_6G4UJ8ZbRL,
  formatHtml_g8oRQLd2uu,
  gtm_faEZkqnVWN,
  intersect_ellX5rVgT3,
  localStorage_MHY01ePQwD,
  pageView_LHDLtaCFew,
  recaptcha_wOTe5uVF3s,
  vue_query_FsGdL7i3dD
]